<template>
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/diagram-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-font/css/bpmn.css">
  <DevChat v-if="isAuthorized" :userName="userName" :userEmail="userEmail" :token="token" :server="server" />
  <LoginScreen v-else @auth-success="handleAuthSuccess" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DevChat from './DevChat.vue';
import LoginScreen from './LoginScreen.vue';
import { GameStreamingService } from './GameManager';
import './App.vue.css';
import { IGameStreamingService } from './types';
import { RealtimeAudioStreamingService } from './RealtimeApiAudioManager';

// Interface defining the structure of component data
interface ComponentData {
  isAuthorized: boolean;
  userName: string;
  userEmail: string;
  token: string;
  server: string;
  gameService: IGameStreamingService | null;
  automab: boolean;
}

export default defineComponent({
  components: {
    DevChat,
    LoginScreen
  },
  data(): ComponentData {
    return {
      isAuthorized: false,
      userName: '',
      userEmail: '',
      token: '',
      server: '',
      gameService: null,
      automab: false
    };
  },
  async created() {
    // Initialize GameService early, but don't connect yet
    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('env');

    if (env) {
      if (env === 'automab') {
        this.automab = true;
      }
    }
    let port = 50054;
    if (this.automab) {
      port = 50055;
    }
    this.gameService = GameStreamingService.initialize(
      port,         // hostPortNum: number
      this.$toast,  // toast: ToastPluginApi
      "",           // userToken: string - empty initially, set after Google auth
      this.automab  // automab: boolean
    );
  },
  methods: {
    async handleAuthSuccess(token: string, user: { name: string; email: string }, server: string) {
      console.log('App received auth success:', { email: user.email, server });
      try {
        this.isAuthorized = true;
        this.userName = user.name;
        this.userEmail = user.email;
        this.token = token;
        this.server = "aitrainer";
        if (this.automab) {
          this.server = "automab";
        }
        console.log('Auth state updated:', { 
          isAuthorized: this.isAuthorized,
          userName: this.userName,
          userEmail: this.userEmail
        });
      } catch (error) {
        console.error('Failed to update auth state:', error);
        this.$toast.error('Failed to complete login');
      }
    },
  },
  beforeUnmount() {
    // Cleanup WebSocket connection when component is destroyed
    if (this.gameService) {  // Type guard
      this.gameService.disconnect();
    }
  }
});
</script>
