<template>
    <div class="bpmn-container">
        <div ref="canvas" class="bpmn-canvas"></div>
        <div ref="propertiesPanel" class="properties-panel"></div>
        <div class="button-container">
            <button @click="factoryReset" class="factory-reset-button" title="Reset to factory default"
                :class="{ 'button-disabled': !canFactoryReset }">
                Factory Reset
            </button>
            <button @click="discardChanges" class="discard-button" :disabled="!hasUnsavedChanges"
                :class="{ 'button-disabled': !hasUnsavedChanges }" title="Discard unsaved changes">
                Discard Changes
            </button>
            <button @click="saveDiagram" class="save-button" :disabled="!hasUnsavedChanges"
                :class="{ 'button-disabled': !hasUnsavedChanges }" title="Save changes">
                Save
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps } from 'vue';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule } from 'bpmn-js-properties-panel';

// Import required CSS
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import '@bpmn-io/properties-panel/assets/properties-panel.css';

const props = defineProps({
    processorService: {
        type: Object,
        required: true
    }
});

const canvas = ref(null);
const propertiesPanel = ref(null);
let modeler = null;
const currentXML = ref('');
const hasUnsavedChanges = ref(false);
const canFactoryReset = ref(true); // Always enabled since it's a factory reset

const loadDiagram = async (xml) => {
    try {
        await modeler.importXML(xml);
        modeler.get('canvas').zoom('fit-viewport');
    } catch (err) {
        console.error('Error importing BPMN XML:', err);
        modeler.createDiagram();
    }
};

onMounted(async () => {
    try {
        const response = await props.processorService.getProcessModelXml();
        currentXML.value = response.processModelXml;
    } catch (error) {
        console.error('Error fetching BPMN XML:', error);
        currentXML.value = '';
    }

    modeler = new BpmnModeler({
        container: canvas.value,
        propertiesPanel: {
            parent: propertiesPanel.value
        },
        additionalModules: [
            BpmnPropertiesPanelModule,
            BpmnPropertiesProviderModule
        ],
        keyboard: { bindTo: window }
    });

    await loadDiagram(currentXML.value);

    const eventBus = modeler.get('eventBus');
    eventBus.on(['elements.changed', 'elements.delete', 'connect.end', 'shape.move.end'], () => {
        hasUnsavedChanges.value = true;
    });
});

const factoryReset = async () => {
    if (confirm('Are you sure you want to reset to factory defaults? This will discard all customizations.')) {
        try {
            const response = await props.processorService.getDefaultProcessModelXml();
            await loadDiagram(response.processModelXml);
            currentXML.value = response.processModelXml;
            hasUnsavedChanges.value = true; // Set to true since we need to save the factory reset
        } catch (error) {
            console.error('Error performing factory reset:', error);
        }
    }
};

const discardChanges = async () => {
    if (confirm('Are you sure you want to discard all unsaved changes?')) {
        try {
            await loadDiagram(currentXML.value);
            hasUnsavedChanges.value = false;
        } catch (error) {
            console.error('Error discarding changes:', error);
        }
    }
};

const saveDiagram = async () => {
    try {
        const { xml } = await modeler.saveXML({ format: true });
        console.log(xml);
        await props.processorService.setProcessModelXml(xml);
        currentXML.value = xml; // Update the current XML after successful save
        hasUnsavedChanges.value = false;
    } catch (err) {
        console.error('Error saving diagram', err);
    }
};

onBeforeUnmount(() => {
    if (modeler) {
        modeler.destroy();
    }
});
</script>

<style scoped>
.bpmn-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.bpmn-canvas {
    flex: 1;
    height: 100%;
}

.properties-panel {
    width: 300px;
    height: 100%;
    overflow-y: auto;
}

.button-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.save-button,
.discard-button,
.factory-reset-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
    font-weight: 500;
}

.save-button {
    background-color: #4CAF50;
}

.discard-button {
    background-color: #ff9800;
}

.factory-reset-button {
    background-color: #f44336;
}

.save-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #45a049;
}

.discard-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #f57c00;
}

.factory-reset-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #da190b;
}

.button-disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}
</style>