import {
  ToGameServer,
  ToGameClient,
  ServerPush,
  ClientReq,
  ClientReqAck,
  LoginRequest,
  ExerciseSet,
  ExerciseSetReport,
  Status

} from '../proto-gen-ref/game_pb';
import { WebSocketManager } from './WebSocketManager';
import { v4 as uuidv4 } from 'uuid';
import { IGameStreamingService, Settings } from './types';
import { RealtimeAudioStreamingService } from './RealtimeApiAudioManager';
import { ToastPluginApi } from 'vue-toast-notification';



interface ResponseSettlers {
  resolve: (value: ToGameClient) => void;
  reject: (reason?: any) => void;
  timeout: ReturnType<typeof setTimeout>;
}

// Constants
const TIMEOUT_DURATION = 10000;
const ERROR_MESSAGES = {
  NO_CREQ: 'sendAndWaitForResponse received non-Creq tgs object',
  RESPONSE_TIMEOUT: 'Response timeout',
  LOGIN_FAILED: 'Login failed',
} as const;

export class GameStreamingService extends WebSocketManager implements IGameStreamingService {
  private responseSettlers = new Map<string, ResponseSettlers>();
  private loginCreqId: string | null = null;
  private settings?: Settings;
  private pushNotificationCallbacks: Array<(pushCase: ServerPush.PushCase, message: any) => void> = [];
  private sessionDataCallbacks: Array<(data: string) => void> = [];
  private automab: boolean;

  private constructor(hostPortNum: number, toast: ToastPluginApi, userToken: string, automab: boolean) {
    super(hostPortNum, toast, userToken);
    this.automab = automab;
  }

  private static instance: GameStreamingService | null = null;
  public static getInstance(): GameStreamingService {
    if (!this.instance) {
      throw new Error('GameStreamingService not initialized');
    }
    return this.instance;
  }

  public static initialize(hostPortNum: number, toast: ToastPluginApi, userToken: string, automab: boolean): GameStreamingService {
    if (this.instance) {
      throw new Error('GameStreamingService already initialized');
    }
    this.instance = new GameStreamingService(hostPortNum, toast, userToken, automab);
    return this.instance;
  }

  setToken(token: string): void {
    this.userToken = token;
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      this.settings = JSON.parse(savedSettings);
      console.log('[GameManager] Loaded settings from localStorage');
    } else {
      console.warn('[GameManager] No settings found in localStorage');
    }
  }

  private getAndRemoveSettlers(creqId: string): Omit<ResponseSettlers, 'timeout'> | undefined {
    const settlers = this.responseSettlers.get(creqId);
    if (settlers) {
      clearTimeout(settlers.timeout);
      this.responseSettlers.delete(creqId);
      const { resolve, reject } = settlers;
      return { resolve, reject };
    }
    return undefined;
  }

  private setupRequest(creqId: string): Promise<ToGameClient> {
    console.log('[GameManager] setupRequest', creqId);

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        if (this.responseSettlers.has(creqId)) {
          console.warn('Request timed out', creqId);
          this.getAndRemoveSettlers(creqId);
          reject(new Error(ERROR_MESSAGES.RESPONSE_TIMEOUT));
        }
      }, TIMEOUT_DURATION);

      this.responseSettlers.set(creqId, { resolve, reject, timeout });
    });
  }

  async sendAndWaitForResponse(tgs: ToGameServer): Promise<ClientReqAck> {
    if (!tgs.getCreq()) {
      throw new Error(ERROR_MESSAGES.NO_CREQ);
    }

    if (!this.isConnected()) {
      await this.connect();
    }

    const creqId = uuidv4();
    console.log('[GameManager] sendAndWaitForResponse', creqId);
    tgs.getCreq()!.setCreqId(creqId);

    const responsePromise = this.setupRequest(creqId);
    this.wsConnection!.send(tgs.serializeBinary());
    const response = await responsePromise;
    return response.getCack()!;
  }

  protected sendConnectRequest(): void {
    const creqId = uuidv4();
    this.loginCreqId = creqId;
    console.log('[GameManager] sendConnectRequest', creqId);
    const loginReq = new LoginRequest();

    const creq = new ClientReq();
    creq.setLogin(loginReq);
    creq.setCreqId(creqId);

    if (this.settings) {
      loginReq.setOpenaiApiKey(this.settings.openAiApiKey);
      loginReq.setChatModel(this.settings.chatModel);
      console.log('[GameManager] Including settings in login request');
    } else {
      console.warn('[GameManager] No settings available for login request');
    }

    loginReq.setToken(this.userToken);

    const tgs = new ToGameServer();
    tgs.setCreq(creq);

    this.setupRequest(creqId);
    this.wsConnection?.send(tgs.serializeBinary());
  }

  protected handleMessage(event: MessageEvent): void {
    console.log('[GameManager] handleMessage');
    const tgc = ToGameClient.deserializeBinary(new Uint8Array(event.data));

    const handlers: Record<ToGameClient.TypeCase, (() => void) | undefined> = {
      [ToGameClient.TypeCase.SESSION_DATA]: () => this.handleSessionData(tgc),
      [ToGameClient.TypeCase.CACK]: () => this.handleCack(tgc),
      [ToGameClient.TypeCase.SPSH]: () => this.handleServerPush(tgc),
      [ToGameClient.TypeCase.SREQ]: undefined,
      [ToGameClient.TypeCase.SPSH_NAMED_MSG_VALS]: undefined,
      [ToGameClient.TypeCase.SPSH_NAMED_VALS]: undefined,
      [ToGameClient.TypeCase.TYPE_NOT_SET]: undefined,
    };

    const handler = handlers[tgc.getTypeCase()];
    if (handler) {
      handler();
    }
  }

  private generateWorkoutSummary = (tgc: ToGameClient) => {
    const summary = [];

    const sessionData = tgc.getSessionData()!;
    const status = this.statusToString(sessionData.getStatus());
    summary.push(`Workout Status: ${status}\n`);

    sessionData.getExercisesList().forEach((exercise, exerciseIndex) => {
      const exerciseName = exercise.getName();
      summary.push(`Exercise ${exerciseIndex + 1}: ${exerciseName}`);

      exercise.getSetsList().forEach((set, setIndex) => {
        const plannedReps = set.getPlannedReps();
        const actualReps = set.getActualReps();
        const status = this.statusToString(set.getStatus());
        const restTime = set.getRestTimeSeconds();
        summary.push(
          `  Set ${setIndex + 1}: Planned ${plannedReps}, Actual ${actualReps}, Status: ${status}, Rest Time: ${restTime} seconds`
        );
      });

      summary.push(""); // Blank line between exercises
    });

    return summary.join("\n");
  };

  private statusToString(status: Status): string {
    switch (status) {
      case Status.NOTSTARTED:
        return "Not Started";
      case Status.INPROGRESS:
        return "In Progress";
      case Status.COMPLETED:
        return "Completed";
      default:
        return "Unknown";
    }
  }

  private handleSessionData(tgc: ToGameClient): void {
    const sessionData = tgc.getSessionData()!;
    console.log('[GameManager] Received SessionData:', sessionData);

    const sessionDataString = JSON.stringify({
      status: sessionData.getStatus(),
      currentExerciseIndex: sessionData.getCurrentExerciseIndex(),
      exercises: sessionData.getExercisesList().map(exercise => ({
        name: exercise.getName(),
        currentSetIndex: exercise.getCurrentSetIndex(),
        sets: exercise.getSetsList().map(set => ({
          status: set.getStatus(),
          plannedReps: set.getPlannedReps(),
          actualReps: set.getActualReps()
        }))
      }))
    }, null, 2);

    const workoutSummary = this.generateWorkoutSummary(tgc);

    this.notifySessionDataSubscribers(workoutSummary);

    const sessionStatus = sessionData.getStatus();
    const currentExercise = sessionData.getExercisesList()[sessionData.getCurrentExerciseIndex()];
    const currentSet = currentExercise.getSetsList()[currentExercise.getCurrentSetIndex()];
    const currentExerciseIndex = sessionData.getCurrentExerciseIndex();
    const currentSetIndex = currentExercise.getCurrentSetIndex();

    // TODO: game would look at the session data and take appropriate action
    // for now, just make like we did the exercise and respond immediately
    // fill out currentSet and send it back
    if (sessionStatus === Status.INPROGRESS) {
      currentSet.setStatus(Status.COMPLETED);
      // update the actuals to equal the planned reps
      currentSet.setActualReps(currentSet.getPlannedReps());
    }

    // fill out the ExerciseSetReport
    const exerciseSetReport = new ExerciseSetReport();
    exerciseSetReport.setExerciseIndex(currentExerciseIndex);
    exerciseSetReport.setSetIndex(currentSetIndex);
    exerciseSetReport.setExerciseSet(currentSet);

    // wait 1 second
    setTimeout(() => {
      // send the updated currentSet back to the server
      this.send(new ToGameServer().setExerciseSetReport(exerciseSetReport));
    }, 1000);
  }

  private notifySessionDataSubscribers(data: string): void {
    this.sessionDataCallbacks.forEach(callback => {
      callback(data);
    });
  }

  public onSessionDataUpdate(callback: (data: string) => void): void {
    this.sessionDataCallbacks.push(callback);
  }

  private handleCack(tgc: ToGameClient): void {
    const cack = tgc.getCack()!;
    const errMsg = cack.getError();
    const creqId = cack.getCreqId();
    console.log(`[GameManager] ${cack.getCackPyldCase()}:`, creqId);

    if (creqId === this.loginCreqId && errMsg.length === 0) {
      console.log('[GameManager] Login successful, connection acknowledged');
      this.connAckd = true;
      this.loginCreqId = null;

      // Check for audio connection key
      const audioConnKey = cack.getAudioConnKey();
      if (audioConnKey) {
        console.log('[GameManager] Received audio connection key, establishing audio connection');
        try {
          // Initialize and get the audio service with correct port number
          const audioService = RealtimeAudioStreamingService.initialize(
            this.automab ? 50053 : 50052,  // Audio server port
            this.toast,
            this.userToken
          );
          audioService.connectWithKey(audioConnKey).catch(error => {
            console.error('[GameManager] Failed to establish audio connection:', error);
            this.toast.error('Failed to establish audio connection', {
              position: 'top-right',
              duration: 5000,
            });
          });
        } catch (error) {
          console.error('[GameManager] Error initializing audio service:', error);
        }
      }
    }

    const settlers = this.getAndRemoveSettlers(creqId);

    if (!settlers) {
      console.error('[GameManager] response w/o resolver', creqId, errMsg);
      return;
    }

    const { resolve, reject } = settlers;

    if (errMsg.length > 0) {
      console.error(`[GameManager] ${cack.getCackPyldCase()} failed:`, errMsg);
      reject(new Error(errMsg));
      return;
    }

    console.log(`[GameManager] ${cack.getCackPyldCase()} successful`, creqId);
    resolve(tgc);
  }

  private handleServerPush(tgc: ToGameClient): void {
    const spsh = tgc.getSpsh();
    if (!spsh) return;

    console.log('[GameManager] Received ServerPush:', spsh.getPushCase());

    const pushCase = spsh.getPushCase();
    let message: any;

    const pushHandlers: Record<ServerPush.PushCase, (() => Promise<any>) | undefined> = {
      [ServerPush.PushCase.PUSH_NOT_SET]: undefined,
      [ServerPush.PushCase.TASK_START]: async () => {
        const msg = spsh.getTaskStart();
        console.log('[GameManager] Handling TASK_START for: ', msg);
        return msg;
      },
      [ServerPush.PushCase.TASK_COMPLETE]: async () => {
        const msg = spsh.getTaskComplete();
        console.log('[GameManager] Handling TASK_COMPLETE for: ', msg);
        return msg;
      },
      [ServerPush.PushCase.EXERCISE_START]: async () => {
        const msg = spsh.getExerciseStart();
        console.log('[GameManager] Handling EXERCISE_START for: ', msg);
        return msg;
      },
      [ServerPush.PushCase.SET_START]: async () => {
        const msg = spsh.getSetStart();
        console.log('[GameManager] Handling SET_STARTED for: ', msg);
        return msg;
      },
    };

    const handler = pushHandlers[pushCase];
    if (handler) {
      handler().then((msg) => {
        message = msg;
        this.notifyPushSubscribers(pushCase, message);
      }).catch(error => {
        console.error('[GameManager] Error handling push:', error);
      });
    } else {
      console.log(`WebSocket connection #${this.connectionCounter} received unknown SPSH:`, pushCase);
    }
  }

  private notifyPushSubscribers(pushCase: ServerPush.PushCase, message: any): void {
    this.pushNotificationCallbacks.forEach(callback => {
      callback(pushCase, message);
    });
  }

  public onPushNotification(callback: (pushCase: ServerPush.PushCase, message: any) => void): void {
    this.pushNotificationCallbacks.push(callback);
  }

  protected onCleanup(): void {
    for (const { timeout } of this.responseSettlers.values()) {
      clearTimeout(timeout);
    }
    this.responseSettlers.clear();
  }

  async send(tgs: ToGameServer): Promise<void> {
    if (!this.isConnected()) {
      await this.connect();
    }

    this.wsConnection!.send(tgs.serializeBinary());
  }
}
