<template>
  <div id="login-screen">
    <img src="./assets/logo.png" alt="Logo" class="centered-logo">
    <div class="logo-button-spacer"></div>
    <div class="signin-container">
      <div id="g_id_onload" data-auto_prompt="false"></div>
      <div class="g_id_signin" data-type="standard"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import './LoginScreen.vue.css';
import { GameStreamingService } from './GameManager';


// Define types for Google Sign-In
declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            callback: (response: { credential: string }) => void;
          }) => void;
          renderButton: (element: HTMLElement, options: {
            theme: string;
            size: string;
          }) => void;
        };
      };
    };
  }
}

// Interface defining the structure of component data
interface ComponentData {
  clientId: string;
  showGoogleSignIn : boolean;
}

export default defineComponent({
  setup() {
    const $toast = useToast();
    return { $toast };
  },
  data(): ComponentData {
    return {
      clientId: '532849151962-gerup4k4k85s5b5n1rq7rnbq573fbgh9.apps.googleusercontent.com',
      showGoogleSignIn: true
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Remove token from URL without page reload
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, '', newUrl);

      this.showGoogleSignIn = false; // Hide Google sign-in initially
      this.handleCredentialResponse({credential: token});
    } else {
      this.loadGoogleSignInScript();
    }
  },
  methods: {
    loadGoogleSignInScript() {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.initializeGoogleSignIn();
      };
      document.head.appendChild(script);
    },
    initializeGoogleSignIn() {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: this.clientId,
          callback: this.handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.querySelector('.g_id_signin') as HTMLElement,
          { theme: 'outline', size: 'large' }
        );
      }
    },
    async loadConfig(): Promise<string> {
        try {
            const response = await fetch('/devconfig.json'); // Adjust path as necessary
            const config = await response.json();
            if (config.developmentIP == "") {
                return `/api`;
            } else {
                return `http://${config.developmentIP}:3003/api`;
            }
        } catch (error) {
            console.error('Failed to load config:', error);
            return `/api`;
        }
    },
    async handleCredentialResponse(response: any) {
        try {
            const gameService = GameStreamingService.getInstance();
            console.log('GameService available:', !!gameService);
            
            const token = response.credential;
            console.log(`Setting token (length: ${token.length})`);
            gameService.setToken(token);
            
            console.log('Starting connection...');
            console.log('Calling connect()...');
            try {
                console.log('About to await connect()');
                const connectPromise = gameService.connect();
                console.log('Connect promise created, awaiting...');
                await connectPromise;
                console.log('Connect completed successfully');
            } catch (error) {
                console.error('Connect failed in try/catch:', error);
                throw error;
            }
            
            console.log('Connection successful, parsing JWT...');
            try {
                const payload = JSON.parse(atob(token.split('.')[1]));
                console.log('JWT parsed successfully:', { 
                    email: payload.email, 
                    name: payload.name 
                });
                
                this.$emit('auth-success', 
                    token,
                    {
                        name: payload.name,
                        email: payload.email
                    },
                    'devicepros.net'
                );
                
                console.log('Auth success emitted');
            } catch (error) {
                console.error('Failed to parse JWT:', error);
                throw error;
            }
            
        } catch (error: any) {
            console.error('Authentication failed:', error);
            this.$toast.error(error.message || 'Authentication failed. Please try again.');
        }
    }
  }
});
</script>
