<template>
    <div class="nested-fields">
        <div v-for="fieldDef in fieldsMetadata" :key="fieldDef.name">
            <DynamicFormField :fieldDef="fieldDef" v-model:value="internalValue[fieldDef.name]" />
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import DynamicFormField from './DynamicFormField.vue';

const props = defineProps({
    value: {
        type: Object,
        default: () => ({})
    },
    fieldsMetadata: {
        type: Array,
        required: true
    }
});

const emit = defineEmits(['update:value']);

const internalValue = computed({
    get: () => props.value,
    set: val => emit('update:value', val)
});
</script>

<style scoped>
.nested-fields {
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 4px;
}
</style>