<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Configuration Editor</h2>
        <button class="close-button" @click="$emit('close')">&times;</button>
      </div>
      <div class="modal-content">
        <div v-if="!configItems || configItems.length === 0">
          Loading...
        </div>
        <div v-else>
          <div class="dropdown-wrapper">
            <select v-model="activeTab">
              <option v-for="(item, index) in configItems" :key="item.id" :value="index">
                {{ item.id }}
              </option>
            </select>
          </div>
          <div class="tab-content">
            <div v-if="currentItem">
              <div v-if="itemFieldsMapArr.length > 0">
                <form @submit.prevent="saveChanges">
                  <DynamicFormField v-for="field in itemFieldsMapArr" :key="field.name" :fieldDef="field"
                    v-model:value="currentItem[field.name]" />
                  <div class="form-actions">
                    <button type="submit" class="save-button">Save</button>
                  </div>
                </form>
              </div>
              <div v-else>
                <p>No metadata available for subtype: {{ currentItem.subtype }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import DynamicFormField from './DynamicFormField.vue';

const props = defineProps({
  processorService: {
    type: Object,
    required: true
  },
  show: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close']);

const configItems = ref([]);
const metadata = ref({});
const header = ref({});
const activeTab = ref(0);

onMounted(async () => {
  const configDataText = await props.processorService.getConfigData();
  const configData = JSON.parse(configDataText.formConfig);
  configItems.value = configData.config;
  header.value = configData.header;
  metadata.value = configData.metadata;
});

const currentItem = computed(() => configItems.value[activeTab.value]);
const itemSubtype = computed(() => currentItem.value?.subtype || null);

function findConfigTypeKeyForSubtype(subtype) {
  if (!subtype) return null;
  for (const [configTypeKey, subTypeVal] of Object.entries(header.value.taskTypes || {})) {
    if (subTypeVal === subtype) {
      return { typeCategory: 'task', configTypeKey };
    }
  }
  for (const [configTypeKey, subTypeVal] of Object.entries(header.value.gateTypes || {})) {
    if (subTypeVal === subtype) {
      return { typeCategory: 'gate', configTypeKey };
    }
  }
  return null;
}

const itemTypeInfo = computed(() => {
  if (!itemSubtype.value) return null;
  return findConfigTypeKeyForSubtype(itemSubtype.value);
});

const itemTypeCategory = computed(() => itemTypeInfo.value?.typeCategory || null);

const itemFieldsMapArr = computed(() => {
  const configTypeKey = itemTypeInfo.value?.configTypeKey;
  if (!configTypeKey) return [];
  const fieldDefs = metadata.value[configTypeKey] || [];
  return fieldDefs;
});

function saveChanges() {
  props.processorService.saveConfigData(configItems.value)
    .then(() => {
      console.log('Configuration saved successfully');
    })
    .catch(error => {
      console.error('Error saving configuration:', error);
    });
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  width: 90vw;
  max-width: 800px;
  height: 90vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  flex-shrink: 0;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
}

.dropdown-wrapper {
  padding: 20px;
}

.modal-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
}

.tab-content {
  margin-top: 1rem;
}

.form-field {
  margin-bottom: 1.5rem;
}

.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-actions {
  margin-top: 20px;
  text-align: right;
}

.save-button {
  background: #007ACC;
  color: #fff;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.3s;
  width: auto;
}

.save-button:hover {
  background: #005fa3;
}
</style>
