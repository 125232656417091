// source: audio.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.audio.AudioFileUpload', null, global);
goog.exportSymbol('proto.audio.AudioPlaybackProgressPt', null, global);
goog.exportSymbol('proto.audio.AudioTranscript', null, global);
goog.exportSymbol('proto.audio.ClientConnectAck', null, global);
goog.exportSymbol('proto.audio.ClientConnectRequest', null, global);
goog.exportSymbol('proto.audio.ClientPush', null, global);
goog.exportSymbol('proto.audio.ClientPush.PushCase', null, global);
goog.exportSymbol('proto.audio.ClientReq', null, global);
goog.exportSymbol('proto.audio.ClientReq.CreqCase', null, global);
goog.exportSymbol('proto.audio.ClientReqAck', null, global);
goog.exportSymbol('proto.audio.ClientReqAck.ResultCase', null, global);
goog.exportSymbol('proto.audio.Empty', null, global);
goog.exportSymbol('proto.audio.Event', null, global);
goog.exportSymbol('proto.audio.GracefulStop', null, global);
goog.exportSymbol('proto.audio.NamedMsgVals', null, global);
goog.exportSymbol('proto.audio.NamedVals', null, global);
goog.exportSymbol('proto.audio.NamedVals.NamedJsNtvParameter', null, global);
goog.exportSymbol('proto.audio.ServerPush', null, global);
goog.exportSymbol('proto.audio.ServerPush.PushCase', null, global);
goog.exportSymbol('proto.audio.ServerReq', null, global);
goog.exportSymbol('proto.audio.ServerReq.SreqCase', null, global);
goog.exportSymbol('proto.audio.ServerReqAck', null, global);
goog.exportSymbol('proto.audio.ServerReqAck.SreqCase', null, global);
goog.exportSymbol('proto.audio.ToAudioClient', null, global);
goog.exportSymbol('proto.audio.ToAudioClient.TypeCase', null, global);
goog.exportSymbol('proto.audio.ToAudioServer', null, global);
goog.exportSymbol('proto.audio.ToAudioServer.ContentCase', null, global);
goog.exportSymbol('proto.audio.ValUnion', null, global);
goog.exportSymbol('proto.audio.ValUnion.ValCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.Empty.displayName = 'proto.audio.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioServer.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioServer.displayName = 'proto.audio.ToAudioServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ValUnion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ValUnion.oneofGroups_);
};
goog.inherits(proto.audio.ValUnion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ValUnion.displayName = 'proto.audio.ValUnion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.NamedMsgVals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audio.NamedMsgVals.repeatedFields_, null);
};
goog.inherits(proto.audio.NamedMsgVals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.NamedMsgVals.displayName = 'proto.audio.NamedMsgVals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.NamedVals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audio.NamedVals.repeatedFields_, null);
};
goog.inherits(proto.audio.NamedVals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.NamedVals.displayName = 'proto.audio.NamedVals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.NamedVals.NamedJsNtvParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.NamedVals.NamedJsNtvParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.NamedVals.NamedJsNtvParameter.displayName = 'proto.audio.NamedVals.NamedJsNtvParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioClient.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioClient.displayName = 'proto.audio.ToAudioClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.ClientConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientConnectRequest.displayName = 'proto.audio.ClientConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientConnectAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.ClientConnectAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientConnectAck.displayName = 'proto.audio.ClientConnectAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.GracefulStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.GracefulStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.GracefulStop.displayName = 'proto.audio.GracefulStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ServerReq.oneofGroups_);
};
goog.inherits(proto.audio.ServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ServerReq.displayName = 'proto.audio.ServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ServerReqAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ServerReqAck.oneofGroups_);
};
goog.inherits(proto.audio.ServerReqAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ServerReqAck.displayName = 'proto.audio.ServerReqAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ClientReq.oneofGroups_);
};
goog.inherits(proto.audio.ClientReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientReq.displayName = 'proto.audio.ClientReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientReqAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ClientReqAck.oneofGroups_);
};
goog.inherits(proto.audio.ClientReqAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientReqAck.displayName = 'proto.audio.ClientReqAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioFileUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioFileUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioFileUpload.displayName = 'proto.audio.AudioFileUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioPlaybackProgressPt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioPlaybackProgressPt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioPlaybackProgressPt.displayName = 'proto.audio.AudioPlaybackProgressPt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientPush = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ClientPush.oneofGroups_);
};
goog.inherits(proto.audio.ClientPush, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientPush.displayName = 'proto.audio.ClientPush';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.Event.displayName = 'proto.audio.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioTranscript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioTranscript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioTranscript.displayName = 'proto.audio.AudioTranscript';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ServerPush = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ServerPush.oneofGroups_);
};
goog.inherits(proto.audio.ServerPush, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ServerPush.displayName = 'proto.audio.ServerPush';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.Empty}
 */
proto.audio.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.Empty;
  return proto.audio.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.Empty}
 */
proto.audio.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioServer.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.audio.ToAudioServer.ContentCase = {
  CONTENT_NOT_SET: 0,
  CONNREQ: 1,
  CREQ: 2,
  CPSH: 3,
  CPSH_NAMED_MSG_VALS: 4,
  CPSH_NAMED_VALS: 5,
  SACK: 6
};

/**
 * @return {proto.audio.ToAudioServer.ContentCase}
 */
proto.audio.ToAudioServer.prototype.getContentCase = function() {
  return /** @type {proto.audio.ToAudioServer.ContentCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioServer.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    connreq: (f = msg.getConnreq()) && proto.audio.ClientConnectRequest.toObject(includeInstance, f),
    creq: (f = msg.getCreq()) && proto.audio.ClientReq.toObject(includeInstance, f),
    cpsh: (f = msg.getCpsh()) && proto.audio.ClientPush.toObject(includeInstance, f),
    cpshNamedMsgVals: (f = msg.getCpshNamedMsgVals()) && proto.audio.NamedMsgVals.toObject(includeInstance, f),
    cpshNamedVals: (f = msg.getCpshNamedVals()) && proto.audio.NamedVals.toObject(includeInstance, f),
    sack: (f = msg.getSack()) && proto.audio.ServerReqAck.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioServer;
  return proto.audio.ToAudioServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.ClientConnectRequest;
      reader.readMessage(value,proto.audio.ClientConnectRequest.deserializeBinaryFromReader);
      msg.setConnreq(value);
      break;
    case 2:
      var value = new proto.audio.ClientReq;
      reader.readMessage(value,proto.audio.ClientReq.deserializeBinaryFromReader);
      msg.setCreq(value);
      break;
    case 3:
      var value = new proto.audio.ClientPush;
      reader.readMessage(value,proto.audio.ClientPush.deserializeBinaryFromReader);
      msg.setCpsh(value);
      break;
    case 4:
      var value = new proto.audio.NamedMsgVals;
      reader.readMessage(value,proto.audio.NamedMsgVals.deserializeBinaryFromReader);
      msg.setCpshNamedMsgVals(value);
      break;
    case 5:
      var value = new proto.audio.NamedVals;
      reader.readMessage(value,proto.audio.NamedVals.deserializeBinaryFromReader);
      msg.setCpshNamedVals(value);
      break;
    case 6:
      var value = new proto.audio.ServerReqAck;
      reader.readMessage(value,proto.audio.ServerReqAck.deserializeBinaryFromReader);
      msg.setSack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnreq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.ClientConnectRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.ClientReq.serializeBinaryToWriter
    );
  }
  f = message.getCpsh();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.ClientPush.serializeBinaryToWriter
    );
  }
  f = message.getCpshNamedMsgVals();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audio.NamedMsgVals.serializeBinaryToWriter
    );
  }
  f = message.getCpshNamedVals();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audio.NamedVals.serializeBinaryToWriter
    );
  }
  f = message.getSack();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.audio.ServerReqAck.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClientConnectRequest connreq = 1;
 * @return {?proto.audio.ClientConnectRequest}
 */
proto.audio.ToAudioServer.prototype.getConnreq = function() {
  return /** @type{?proto.audio.ClientConnectRequest} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientConnectRequest, 1));
};


/**
 * @param {?proto.audio.ClientConnectRequest|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setConnreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearConnreq = function() {
  return this.setConnreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasConnreq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ClientReq creq = 2;
 * @return {?proto.audio.ClientReq}
 */
proto.audio.ToAudioServer.prototype.getCreq = function() {
  return /** @type{?proto.audio.ClientReq} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientReq, 2));
};


/**
 * @param {?proto.audio.ClientReq|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setCreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearCreq = function() {
  return this.setCreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasCreq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClientPush cpsh = 3;
 * @return {?proto.audio.ClientPush}
 */
proto.audio.ToAudioServer.prototype.getCpsh = function() {
  return /** @type{?proto.audio.ClientPush} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientPush, 3));
};


/**
 * @param {?proto.audio.ClientPush|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setCpsh = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearCpsh = function() {
  return this.setCpsh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasCpsh = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NamedMsgVals cpsh_named_msg_vals = 4;
 * @return {?proto.audio.NamedMsgVals}
 */
proto.audio.ToAudioServer.prototype.getCpshNamedMsgVals = function() {
  return /** @type{?proto.audio.NamedMsgVals} */ (
    jspb.Message.getWrapperField(this, proto.audio.NamedMsgVals, 4));
};


/**
 * @param {?proto.audio.NamedMsgVals|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setCpshNamedMsgVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearCpshNamedMsgVals = function() {
  return this.setCpshNamedMsgVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasCpshNamedMsgVals = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NamedVals cpsh_named_vals = 5;
 * @return {?proto.audio.NamedVals}
 */
proto.audio.ToAudioServer.prototype.getCpshNamedVals = function() {
  return /** @type{?proto.audio.NamedVals} */ (
    jspb.Message.getWrapperField(this, proto.audio.NamedVals, 5));
};


/**
 * @param {?proto.audio.NamedVals|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setCpshNamedVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearCpshNamedVals = function() {
  return this.setCpshNamedVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasCpshNamedVals = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ServerReqAck sack = 6;
 * @return {?proto.audio.ServerReqAck}
 */
proto.audio.ToAudioServer.prototype.getSack = function() {
  return /** @type{?proto.audio.ServerReqAck} */ (
    jspb.Message.getWrapperField(this, proto.audio.ServerReqAck, 6));
};


/**
 * @param {?proto.audio.ServerReqAck|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setSack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearSack = function() {
  return this.setSack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasSack = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ValUnion.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.audio.ValUnion.ValCase = {
  VAL_NOT_SET: 0,
  STR_VAL: 1,
  BIN_VAL: 2,
  NUM_VAL: 3,
  U32_VAL: 4,
  S32_VAL: 5,
  BOOL_VAL: 6
};

/**
 * @return {proto.audio.ValUnion.ValCase}
 */
proto.audio.ValUnion.prototype.getValCase = function() {
  return /** @type {proto.audio.ValUnion.ValCase} */(jspb.Message.computeOneofCase(this, proto.audio.ValUnion.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ValUnion.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ValUnion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ValUnion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ValUnion.toObject = function(includeInstance, msg) {
  var f, obj = {
    strVal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    binVal: msg.getBinVal_asB64(),
    numVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    u32Val: jspb.Message.getFieldWithDefault(msg, 4, 0),
    s32Val: jspb.Message.getFieldWithDefault(msg, 5, 0),
    boolVal: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ValUnion}
 */
proto.audio.ValUnion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ValUnion;
  return proto.audio.ValUnion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ValUnion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ValUnion}
 */
proto.audio.ValUnion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrVal(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBinVal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumVal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setU32Val(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setS32Val(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ValUnion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ValUnion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ValUnion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ValUnion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string str_val = 1;
 * @return {string}
 */
proto.audio.ValUnion.prototype.getStrVal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setStrVal = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearStrVal = function() {
  return jspb.Message.setOneofField(this, 1, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasStrVal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes bin_val = 2;
 * @return {!(string|Uint8Array)}
 */
proto.audio.ValUnion.prototype.getBinVal = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes bin_val = 2;
 * This is a type-conversion wrapper around `getBinVal()`
 * @return {string}
 */
proto.audio.ValUnion.prototype.getBinVal_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBinVal()));
};


/**
 * optional bytes bin_val = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBinVal()`
 * @return {!Uint8Array}
 */
proto.audio.ValUnion.prototype.getBinVal_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBinVal()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setBinVal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearBinVal = function() {
  return jspb.Message.setOneofField(this, 2, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasBinVal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double num_val = 3;
 * @return {number}
 */
proto.audio.ValUnion.prototype.getNumVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setNumVal = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearNumVal = function() {
  return jspb.Message.setOneofField(this, 3, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasNumVal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 u32_val = 4;
 * @return {number}
 */
proto.audio.ValUnion.prototype.getU32Val = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setU32Val = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearU32Val = function() {
  return jspb.Message.setOneofField(this, 4, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasU32Val = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sint32 s32_val = 5;
 * @return {number}
 */
proto.audio.ValUnion.prototype.getS32Val = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setS32Val = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearS32Val = function() {
  return jspb.Message.setOneofField(this, 5, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasS32Val = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool bool_val = 6;
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.getBoolVal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.setBoolVal = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.audio.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ValUnion} returns this
 */
proto.audio.ValUnion.prototype.clearBoolVal = function() {
  return jspb.Message.setOneofField(this, 6, proto.audio.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ValUnion.prototype.hasBoolVal = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audio.NamedMsgVals.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.NamedMsgVals.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.NamedMsgVals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.NamedMsgVals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedMsgVals.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valsList: jspb.Message.toObjectList(msg.getValsList(),
    proto.audio.ValUnion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.NamedMsgVals}
 */
proto.audio.NamedMsgVals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.NamedMsgVals;
  return proto.audio.NamedMsgVals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.NamedMsgVals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.NamedMsgVals}
 */
proto.audio.NamedMsgVals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.audio.ValUnion;
      reader.readMessage(value,proto.audio.ValUnion.deserializeBinaryFromReader);
      msg.addVals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.NamedMsgVals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.NamedMsgVals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.NamedMsgVals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedMsgVals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.audio.ValUnion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.audio.NamedMsgVals.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.NamedMsgVals} returns this
 */
proto.audio.NamedMsgVals.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ValUnion vals = 2;
 * @return {!Array<!proto.audio.ValUnion>}
 */
proto.audio.NamedMsgVals.prototype.getValsList = function() {
  return /** @type{!Array<!proto.audio.ValUnion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audio.ValUnion, 2));
};


/**
 * @param {!Array<!proto.audio.ValUnion>} value
 * @return {!proto.audio.NamedMsgVals} returns this
*/
proto.audio.NamedMsgVals.prototype.setValsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.audio.ValUnion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audio.ValUnion}
 */
proto.audio.NamedMsgVals.prototype.addVals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.audio.ValUnion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audio.NamedMsgVals} returns this
 */
proto.audio.NamedMsgVals.prototype.clearValsList = function() {
  return this.setValsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audio.NamedVals.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.NamedVals.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.NamedVals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.NamedVals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedVals.toObject = function(includeInstance, msg) {
  var f, obj = {
    npList: jspb.Message.toObjectList(msg.getNpList(),
    proto.audio.NamedVals.NamedJsNtvParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.NamedVals}
 */
proto.audio.NamedVals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.NamedVals;
  return proto.audio.NamedVals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.NamedVals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.NamedVals}
 */
proto.audio.NamedVals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.NamedVals.NamedJsNtvParameter;
      reader.readMessage(value,proto.audio.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader);
      msg.addNp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.NamedVals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.NamedVals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.NamedVals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedVals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNpList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audio.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.NamedVals.NamedJsNtvParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.NamedVals.NamedJsNtvParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedVals.NamedJsNtvParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    val: (f = msg.getVal()) && proto.audio.ValUnion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter}
 */
proto.audio.NamedVals.NamedJsNtvParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.NamedVals.NamedJsNtvParameter;
  return proto.audio.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.NamedVals.NamedJsNtvParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter}
 */
proto.audio.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.audio.ValUnion;
      reader.readMessage(value,proto.audio.ValUnion.deserializeBinaryFromReader);
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.NamedVals.NamedJsNtvParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.ValUnion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter} returns this
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ValUnion val = 2;
 * @return {?proto.audio.ValUnion}
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.getVal = function() {
  return /** @type{?proto.audio.ValUnion} */ (
    jspb.Message.getWrapperField(this, proto.audio.ValUnion, 2));
};


/**
 * @param {?proto.audio.ValUnion|undefined} value
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter} returns this
*/
proto.audio.NamedVals.NamedJsNtvParameter.prototype.setVal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter} returns this
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.clearVal = function() {
  return this.setVal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.NamedVals.NamedJsNtvParameter.prototype.hasVal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NamedJsNtvParameter np = 1;
 * @return {!Array<!proto.audio.NamedVals.NamedJsNtvParameter>}
 */
proto.audio.NamedVals.prototype.getNpList = function() {
  return /** @type{!Array<!proto.audio.NamedVals.NamedJsNtvParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audio.NamedVals.NamedJsNtvParameter, 1));
};


/**
 * @param {!Array<!proto.audio.NamedVals.NamedJsNtvParameter>} value
 * @return {!proto.audio.NamedVals} returns this
*/
proto.audio.NamedVals.prototype.setNpList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audio.NamedVals.NamedJsNtvParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audio.NamedVals.NamedJsNtvParameter}
 */
proto.audio.NamedVals.prototype.addNp = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audio.NamedVals.NamedJsNtvParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audio.NamedVals} returns this
 */
proto.audio.NamedVals.prototype.clearNpList = function() {
  return this.setNpList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioClient.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.audio.ToAudioClient.TypeCase = {
  TYPE_NOT_SET: 0,
  CONNACK: 1,
  SREQ: 2,
  SPSH: 3,
  SPSH_NAMED_MSG_VALS: 4,
  SPSH_NAMED_VALS: 5,
  CACK: 6
};

/**
 * @return {proto.audio.ToAudioClient.TypeCase}
 */
proto.audio.ToAudioClient.prototype.getTypeCase = function() {
  return /** @type {proto.audio.ToAudioClient.TypeCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioClient.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioClient.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    connack: (f = msg.getConnack()) && proto.audio.ClientConnectAck.toObject(includeInstance, f),
    sreq: (f = msg.getSreq()) && proto.audio.ServerReq.toObject(includeInstance, f),
    spsh: (f = msg.getSpsh()) && proto.audio.ServerPush.toObject(includeInstance, f),
    spshNamedMsgVals: (f = msg.getSpshNamedMsgVals()) && proto.audio.NamedMsgVals.toObject(includeInstance, f),
    spshNamedVals: (f = msg.getSpshNamedVals()) && proto.audio.NamedVals.toObject(includeInstance, f),
    cack: (f = msg.getCack()) && proto.audio.ClientReqAck.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioClient;
  return proto.audio.ToAudioClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.ClientConnectAck;
      reader.readMessage(value,proto.audio.ClientConnectAck.deserializeBinaryFromReader);
      msg.setConnack(value);
      break;
    case 2:
      var value = new proto.audio.ServerReq;
      reader.readMessage(value,proto.audio.ServerReq.deserializeBinaryFromReader);
      msg.setSreq(value);
      break;
    case 3:
      var value = new proto.audio.ServerPush;
      reader.readMessage(value,proto.audio.ServerPush.deserializeBinaryFromReader);
      msg.setSpsh(value);
      break;
    case 4:
      var value = new proto.audio.NamedMsgVals;
      reader.readMessage(value,proto.audio.NamedMsgVals.deserializeBinaryFromReader);
      msg.setSpshNamedMsgVals(value);
      break;
    case 5:
      var value = new proto.audio.NamedVals;
      reader.readMessage(value,proto.audio.NamedVals.deserializeBinaryFromReader);
      msg.setSpshNamedVals(value);
      break;
    case 6:
      var value = new proto.audio.ClientReqAck;
      reader.readMessage(value,proto.audio.ClientReqAck.deserializeBinaryFromReader);
      msg.setCack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnack();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.ClientConnectAck.serializeBinaryToWriter
    );
  }
  f = message.getSreq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.ServerReq.serializeBinaryToWriter
    );
  }
  f = message.getSpsh();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.ServerPush.serializeBinaryToWriter
    );
  }
  f = message.getSpshNamedMsgVals();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audio.NamedMsgVals.serializeBinaryToWriter
    );
  }
  f = message.getSpshNamedVals();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audio.NamedVals.serializeBinaryToWriter
    );
  }
  f = message.getCack();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.audio.ClientReqAck.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClientConnectAck connack = 1;
 * @return {?proto.audio.ClientConnectAck}
 */
proto.audio.ToAudioClient.prototype.getConnack = function() {
  return /** @type{?proto.audio.ClientConnectAck} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientConnectAck, 1));
};


/**
 * @param {?proto.audio.ClientConnectAck|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setConnack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearConnack = function() {
  return this.setConnack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasConnack = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServerReq sreq = 2;
 * @return {?proto.audio.ServerReq}
 */
proto.audio.ToAudioClient.prototype.getSreq = function() {
  return /** @type{?proto.audio.ServerReq} */ (
    jspb.Message.getWrapperField(this, proto.audio.ServerReq, 2));
};


/**
 * @param {?proto.audio.ServerReq|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setSreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearSreq = function() {
  return this.setSreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasSreq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ServerPush spsh = 3;
 * @return {?proto.audio.ServerPush}
 */
proto.audio.ToAudioClient.prototype.getSpsh = function() {
  return /** @type{?proto.audio.ServerPush} */ (
    jspb.Message.getWrapperField(this, proto.audio.ServerPush, 3));
};


/**
 * @param {?proto.audio.ServerPush|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setSpsh = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearSpsh = function() {
  return this.setSpsh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasSpsh = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NamedMsgVals spsh_named_msg_vals = 4;
 * @return {?proto.audio.NamedMsgVals}
 */
proto.audio.ToAudioClient.prototype.getSpshNamedMsgVals = function() {
  return /** @type{?proto.audio.NamedMsgVals} */ (
    jspb.Message.getWrapperField(this, proto.audio.NamedMsgVals, 4));
};


/**
 * @param {?proto.audio.NamedMsgVals|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setSpshNamedMsgVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearSpshNamedMsgVals = function() {
  return this.setSpshNamedMsgVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasSpshNamedMsgVals = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NamedVals spsh_named_vals = 5;
 * @return {?proto.audio.NamedVals}
 */
proto.audio.ToAudioClient.prototype.getSpshNamedVals = function() {
  return /** @type{?proto.audio.NamedVals} */ (
    jspb.Message.getWrapperField(this, proto.audio.NamedVals, 5));
};


/**
 * @param {?proto.audio.NamedVals|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setSpshNamedVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearSpshNamedVals = function() {
  return this.setSpshNamedVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasSpshNamedVals = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ClientReqAck cack = 6;
 * @return {?proto.audio.ClientReqAck}
 */
proto.audio.ToAudioClient.prototype.getCack = function() {
  return /** @type{?proto.audio.ClientReqAck} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientReqAck, 6));
};


/**
 * @param {?proto.audio.ClientReqAck|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setCack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearCack = function() {
  return this.setCack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasCack = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    conversationNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientConnectRequest;
  return proto.audio.ClientConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConversationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConversationNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string auth_key = 1;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_ident = 2;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string open_ai_api_key = 3;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string open_ai_chat_model = 4;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 conversation_number = 5;
 * @return {number}
 */
proto.audio.ClientConnectRequest.prototype.getConversationNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setConversationNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientConnectAck.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientConnectAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientConnectAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    telltale: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientConnectAck}
 */
proto.audio.ClientConnectAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientConnectAck;
  return proto.audio.ClientConnectAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientConnectAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientConnectAck}
 */
proto.audio.ClientConnectAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelltale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientConnectAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientConnectAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientConnectAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTelltale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string telltale = 1;
 * @return {string}
 */
proto.audio.ClientConnectAck.prototype.getTelltale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectAck} returns this
 */
proto.audio.ClientConnectAck.prototype.setTelltale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.GracefulStop.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.GracefulStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.GracefulStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.GracefulStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxPlaybackCompletionTmMs: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.GracefulStop}
 */
proto.audio.GracefulStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.GracefulStop;
  return proto.audio.GracefulStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.GracefulStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.GracefulStop}
 */
proto.audio.GracefulStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPlaybackCompletionTmMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.GracefulStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.GracefulStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.GracefulStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.GracefulStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxPlaybackCompletionTmMs();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 max_playback_completion_tm_ms = 1;
 * @return {number}
 */
proto.audio.GracefulStop.prototype.getMaxPlaybackCompletionTmMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.GracefulStop} returns this
 */
proto.audio.GracefulStop.prototype.setMaxPlaybackCompletionTmMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ServerReq.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.audio.ServerReq.SreqCase = {
  SREQ_NOT_SET: 0,
  NOP_SREQ: 2,
  REALTIME_CONVERSATION_STOP: 3
};

/**
 * @return {proto.audio.ServerReq.SreqCase}
 */
proto.audio.ServerReq.prototype.getSreqCase = function() {
  return /** @type {proto.audio.ServerReq.SreqCase} */(jspb.Message.computeOneofCase(this, proto.audio.ServerReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sreqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nopSreq: (f = msg.getNopSreq()) && proto.audio.Empty.toObject(includeInstance, f),
    realtimeConversationStop: (f = msg.getRealtimeConversationStop()) && proto.audio.GracefulStop.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ServerReq}
 */
proto.audio.ServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ServerReq;
  return proto.audio.ServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ServerReq}
 */
proto.audio.ServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSreqId(value);
      break;
    case 2:
      var value = new proto.audio.Empty;
      reader.readMessage(value,proto.audio.Empty.deserializeBinaryFromReader);
      msg.setNopSreq(value);
      break;
    case 3:
      var value = new proto.audio.GracefulStop;
      reader.readMessage(value,proto.audio.GracefulStop.deserializeBinaryFromReader);
      msg.setRealtimeConversationStop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNopSreq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.Empty.serializeBinaryToWriter
    );
  }
  f = message.getRealtimeConversationStop();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.GracefulStop.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sreq_id = 1;
 * @return {string}
 */
proto.audio.ServerReq.prototype.getSreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ServerReq} returns this
 */
proto.audio.ServerReq.prototype.setSreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Empty nop_sreq = 2;
 * @return {?proto.audio.Empty}
 */
proto.audio.ServerReq.prototype.getNopSreq = function() {
  return /** @type{?proto.audio.Empty} */ (
    jspb.Message.getWrapperField(this, proto.audio.Empty, 2));
};


/**
 * @param {?proto.audio.Empty|undefined} value
 * @return {!proto.audio.ServerReq} returns this
*/
proto.audio.ServerReq.prototype.setNopSreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ServerReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerReq} returns this
 */
proto.audio.ServerReq.prototype.clearNopSreq = function() {
  return this.setNopSreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerReq.prototype.hasNopSreq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GracefulStop realtime_conversation_stop = 3;
 * @return {?proto.audio.GracefulStop}
 */
proto.audio.ServerReq.prototype.getRealtimeConversationStop = function() {
  return /** @type{?proto.audio.GracefulStop} */ (
    jspb.Message.getWrapperField(this, proto.audio.GracefulStop, 3));
};


/**
 * @param {?proto.audio.GracefulStop|undefined} value
 * @return {!proto.audio.ServerReq} returns this
*/
proto.audio.ServerReq.prototype.setRealtimeConversationStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ServerReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerReq} returns this
 */
proto.audio.ServerReq.prototype.clearRealtimeConversationStop = function() {
  return this.setRealtimeConversationStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerReq.prototype.hasRealtimeConversationStop = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ServerReqAck.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.audio.ServerReqAck.SreqCase = {
  SREQ_NOT_SET: 0,
  NOP_SACK: 2,
  GRACEFULLY_STOPPING_AT: 3,
  GRACEFUL_STOP_DONE: 4
};

/**
 * @return {proto.audio.ServerReqAck.SreqCase}
 */
proto.audio.ServerReqAck.prototype.getSreqCase = function() {
  return /** @type {proto.audio.ServerReqAck.SreqCase} */(jspb.Message.computeOneofCase(this, proto.audio.ServerReqAck.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ServerReqAck.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ServerReqAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ServerReqAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerReqAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    sreqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nopSack: (f = msg.getNopSack()) && proto.audio.Empty.toObject(includeInstance, f),
    gracefullyStoppingAt: (f = msg.getGracefullyStoppingAt()) && proto.audio.AudioPlaybackProgressPt.toObject(includeInstance, f),
    gracefulStopDone: (f = msg.getGracefulStopDone()) && proto.audio.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ServerReqAck}
 */
proto.audio.ServerReqAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ServerReqAck;
  return proto.audio.ServerReqAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ServerReqAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ServerReqAck}
 */
proto.audio.ServerReqAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSreqId(value);
      break;
    case 2:
      var value = new proto.audio.Empty;
      reader.readMessage(value,proto.audio.Empty.deserializeBinaryFromReader);
      msg.setNopSack(value);
      break;
    case 3:
      var value = new proto.audio.AudioPlaybackProgressPt;
      reader.readMessage(value,proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader);
      msg.setGracefullyStoppingAt(value);
      break;
    case 4:
      var value = new proto.audio.Empty;
      reader.readMessage(value,proto.audio.Empty.deserializeBinaryFromReader);
      msg.setGracefulStopDone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ServerReqAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ServerReqAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ServerReqAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerReqAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNopSack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGracefullyStoppingAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter
    );
  }
  f = message.getGracefulStopDone();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audio.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sreq_id = 1;
 * @return {string}
 */
proto.audio.ServerReqAck.prototype.getSreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ServerReqAck} returns this
 */
proto.audio.ServerReqAck.prototype.setSreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Empty nop_sack = 2;
 * @return {?proto.audio.Empty}
 */
proto.audio.ServerReqAck.prototype.getNopSack = function() {
  return /** @type{?proto.audio.Empty} */ (
    jspb.Message.getWrapperField(this, proto.audio.Empty, 2));
};


/**
 * @param {?proto.audio.Empty|undefined} value
 * @return {!proto.audio.ServerReqAck} returns this
*/
proto.audio.ServerReqAck.prototype.setNopSack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ServerReqAck.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerReqAck} returns this
 */
proto.audio.ServerReqAck.prototype.clearNopSack = function() {
  return this.setNopSack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerReqAck.prototype.hasNopSack = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AudioPlaybackProgressPt gracefully_stopping_at = 3;
 * @return {?proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.ServerReqAck.prototype.getGracefullyStoppingAt = function() {
  return /** @type{?proto.audio.AudioPlaybackProgressPt} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackProgressPt, 3));
};


/**
 * @param {?proto.audio.AudioPlaybackProgressPt|undefined} value
 * @return {!proto.audio.ServerReqAck} returns this
*/
proto.audio.ServerReqAck.prototype.setGracefullyStoppingAt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ServerReqAck.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerReqAck} returns this
 */
proto.audio.ServerReqAck.prototype.clearGracefullyStoppingAt = function() {
  return this.setGracefullyStoppingAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerReqAck.prototype.hasGracefullyStoppingAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Empty graceful_stop_done = 4;
 * @return {?proto.audio.Empty}
 */
proto.audio.ServerReqAck.prototype.getGracefulStopDone = function() {
  return /** @type{?proto.audio.Empty} */ (
    jspb.Message.getWrapperField(this, proto.audio.Empty, 4));
};


/**
 * @param {?proto.audio.Empty|undefined} value
 * @return {!proto.audio.ServerReqAck} returns this
*/
proto.audio.ServerReqAck.prototype.setGracefulStopDone = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.audio.ServerReqAck.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerReqAck} returns this
 */
proto.audio.ServerReqAck.prototype.clearGracefulStopDone = function() {
  return this.setGracefulStopDone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerReqAck.prototype.hasGracefulStopDone = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ClientReq.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.audio.ClientReq.CreqCase = {
  CREQ_NOT_SET: 0,
  NOP_CREQ: 2
};

/**
 * @return {proto.audio.ClientReq.CreqCase}
 */
proto.audio.ClientReq.prototype.getCreqCase = function() {
  return /** @type {proto.audio.ClientReq.CreqCase} */(jspb.Message.computeOneofCase(this, proto.audio.ClientReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientReq.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    creqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nopCreq: (f = msg.getNopCreq()) && proto.audio.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientReq}
 */
proto.audio.ClientReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientReq;
  return proto.audio.ClientReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientReq}
 */
proto.audio.ClientReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreqId(value);
      break;
    case 2:
      var value = new proto.audio.Empty;
      reader.readMessage(value,proto.audio.Empty.deserializeBinaryFromReader);
      msg.setNopCreq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNopCreq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string creq_id = 1;
 * @return {string}
 */
proto.audio.ClientReq.prototype.getCreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientReq} returns this
 */
proto.audio.ClientReq.prototype.setCreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Empty nop_creq = 2;
 * @return {?proto.audio.Empty}
 */
proto.audio.ClientReq.prototype.getNopCreq = function() {
  return /** @type{?proto.audio.Empty} */ (
    jspb.Message.getWrapperField(this, proto.audio.Empty, 2));
};


/**
 * @param {?proto.audio.Empty|undefined} value
 * @return {!proto.audio.ClientReq} returns this
*/
proto.audio.ClientReq.prototype.setNopCreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ClientReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ClientReq} returns this
 */
proto.audio.ClientReq.prototype.clearNopCreq = function() {
  return this.setNopCreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ClientReq.prototype.hasNopCreq = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ClientReqAck.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.audio.ClientReqAck.ResultCase = {
  RESULT_NOT_SET: 0,
  NOP_CACK: 3
};

/**
 * @return {proto.audio.ClientReqAck.ResultCase}
 */
proto.audio.ClientReqAck.prototype.getResultCase = function() {
  return /** @type {proto.audio.ClientReqAck.ResultCase} */(jspb.Message.computeOneofCase(this, proto.audio.ClientReqAck.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientReqAck.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientReqAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientReqAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientReqAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    creqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nopCack: (f = msg.getNopCack()) && proto.audio.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientReqAck}
 */
proto.audio.ClientReqAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientReqAck;
  return proto.audio.ClientReqAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientReqAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientReqAck}
 */
proto.audio.ClientReqAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new proto.audio.Empty;
      reader.readMessage(value,proto.audio.Empty.deserializeBinaryFromReader);
      msg.setNopCack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientReqAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientReqAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientReqAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientReqAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNopCack();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string creq_id = 1;
 * @return {string}
 */
proto.audio.ClientReqAck.prototype.getCreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientReqAck} returns this
 */
proto.audio.ClientReqAck.prototype.setCreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.audio.ClientReqAck.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientReqAck} returns this
 */
proto.audio.ClientReqAck.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Empty nop_cack = 3;
 * @return {?proto.audio.Empty}
 */
proto.audio.ClientReqAck.prototype.getNopCack = function() {
  return /** @type{?proto.audio.Empty} */ (
    jspb.Message.getWrapperField(this, proto.audio.Empty, 3));
};


/**
 * @param {?proto.audio.Empty|undefined} value
 * @return {!proto.audio.ClientReqAck} returns this
*/
proto.audio.ClientReqAck.prototype.setNopCack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ClientReqAck.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ClientReqAck} returns this
 */
proto.audio.ClientReqAck.prototype.clearNopCack = function() {
  return this.setNopCack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ClientReqAck.prototype.hasNopCack = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioFileUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioFileUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioFileUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
    audio: msg.getAudio_asB64(),
    audioType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sequenceNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phaseNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    audioDurationMs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    userIdent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioFileUpload;
  return proto.audio.AudioFileUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioFileUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAudio(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequenceNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAudioDurationMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioFileUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioFileUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudio_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAudioType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSequenceNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPhaseNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAudioDurationMs();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bytes audio = 1;
 * @return {!(string|Uint8Array)}
 */
proto.audio.AudioFileUpload.prototype.getAudio = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes audio = 1;
 * This is a type-conversion wrapper around `getAudio()`
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAudio()));
};


/**
 * optional bytes audio = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAudio()`
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAudio()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudio = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string audio_type = 2;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudioType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 sequence_number = 5;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 phase_number = 6;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getPhaseNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setPhaseNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 audio_duration_ms = 10;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getAudioDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string user_ident = 7;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string open_ai_api_key = 8;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string open_ai_chat_model = 9;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioPlaybackProgressPt.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioPlaybackProgressPt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioPlaybackProgressPt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackProgressPt.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offsetMs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.AudioPlaybackProgressPt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioPlaybackProgressPt;
  return proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioPlaybackProgressPt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffsetMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioPlaybackProgressPt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioPlaybackProgressPt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffsetMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.audio.AudioPlaybackProgressPt.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioPlaybackProgressPt} returns this
 */
proto.audio.AudioPlaybackProgressPt.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 offset_ms = 2;
 * @return {number}
 */
proto.audio.AudioPlaybackProgressPt.prototype.getOffsetMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioPlaybackProgressPt} returns this
 */
proto.audio.AudioPlaybackProgressPt.prototype.setOffsetMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ClientPush.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.audio.ClientPush.PushCase = {
  PUSH_NOT_SET: 0,
  AUDIO_REQUEST: 1,
  AUDIO_PLAYBACK_CANCELLED_AT: 2,
  AUDIO_PLAYBACK_STATUS: 3
};

/**
 * @return {proto.audio.ClientPush.PushCase}
 */
proto.audio.ClientPush.prototype.getPushCase = function() {
  return /** @type {proto.audio.ClientPush.PushCase} */(jspb.Message.computeOneofCase(this, proto.audio.ClientPush.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientPush.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientPush.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientPush} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientPush.toObject = function(includeInstance, msg) {
  var f, obj = {
    audioRequest: (f = msg.getAudioRequest()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    audioPlaybackCancelledAt: (f = msg.getAudioPlaybackCancelledAt()) && proto.audio.AudioPlaybackProgressPt.toObject(includeInstance, f),
    audioPlaybackStatus: (f = msg.getAudioPlaybackStatus()) && proto.audio.AudioPlaybackProgressPt.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientPush}
 */
proto.audio.ClientPush.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientPush;
  return proto.audio.ClientPush.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientPush} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientPush}
 */
proto.audio.ClientPush.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioRequest(value);
      break;
    case 2:
      var value = new proto.audio.AudioPlaybackProgressPt;
      reader.readMessage(value,proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader);
      msg.setAudioPlaybackCancelledAt(value);
      break;
    case 3:
      var value = new proto.audio.AudioPlaybackProgressPt;
      reader.readMessage(value,proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader);
      msg.setAudioPlaybackStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientPush.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientPush.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientPush} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientPush.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudioRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackCancelledAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter
    );
  }
};


/**
 * optional AudioFileUpload audio_request = 1;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ClientPush.prototype.getAudioRequest = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 1));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ClientPush} returns this
*/
proto.audio.ClientPush.prototype.setAudioRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ClientPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ClientPush} returns this
 */
proto.audio.ClientPush.prototype.clearAudioRequest = function() {
  return this.setAudioRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ClientPush.prototype.hasAudioRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioPlaybackProgressPt audio_playback_cancelled_at = 2;
 * @return {?proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.ClientPush.prototype.getAudioPlaybackCancelledAt = function() {
  return /** @type{?proto.audio.AudioPlaybackProgressPt} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackProgressPt, 2));
};


/**
 * @param {?proto.audio.AudioPlaybackProgressPt|undefined} value
 * @return {!proto.audio.ClientPush} returns this
*/
proto.audio.ClientPush.prototype.setAudioPlaybackCancelledAt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ClientPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ClientPush} returns this
 */
proto.audio.ClientPush.prototype.clearAudioPlaybackCancelledAt = function() {
  return this.setAudioPlaybackCancelledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ClientPush.prototype.hasAudioPlaybackCancelledAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AudioPlaybackProgressPt audio_playback_status = 3;
 * @return {?proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.ClientPush.prototype.getAudioPlaybackStatus = function() {
  return /** @type{?proto.audio.AudioPlaybackProgressPt} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackProgressPt, 3));
};


/**
 * @param {?proto.audio.AudioPlaybackProgressPt|undefined} value
 * @return {!proto.audio.ClientPush} returns this
*/
proto.audio.ClientPush.prototype.setAudioPlaybackStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ClientPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ClientPush} returns this
 */
proto.audio.ClientPush.prototype.clearAudioPlaybackStatus = function() {
  return this.setAudioPlaybackStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ClientPush.prototype.hasAudioPlaybackStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.Event}
 */
proto.audio.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.Event;
  return proto.audio.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.Event}
 */
proto.audio.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.audio.Event.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.Event} returns this
 */
proto.audio.Event.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioTranscript.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioTranscript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioTranscript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioTranscript;
  return proto.audio.AudioTranscript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioTranscript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioTranscript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioTranscript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioTranscript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ServerPush.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.audio.ServerPush.PushCase = {
  PUSH_NOT_SET: 0,
  AUDIO_RESPONSE: 1,
  AUDIO_PLAYBACK_CANCEL: 2,
  AUDIO_TRANSCRIPT: 3
};

/**
 * @return {proto.audio.ServerPush.PushCase}
 */
proto.audio.ServerPush.prototype.getPushCase = function() {
  return /** @type {proto.audio.ServerPush.PushCase} */(jspb.Message.computeOneofCase(this, proto.audio.ServerPush.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ServerPush.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ServerPush.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ServerPush} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerPush.toObject = function(includeInstance, msg) {
  var f, obj = {
    audioResponse: (f = msg.getAudioResponse()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    audioPlaybackCancel: (f = msg.getAudioPlaybackCancel()) && proto.audio.Event.toObject(includeInstance, f),
    audioTranscript: (f = msg.getAudioTranscript()) && proto.audio.AudioTranscript.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ServerPush}
 */
proto.audio.ServerPush.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ServerPush;
  return proto.audio.ServerPush.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ServerPush} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ServerPush}
 */
proto.audio.ServerPush.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioResponse(value);
      break;
    case 2:
      var value = new proto.audio.Event;
      reader.readMessage(value,proto.audio.Event.deserializeBinaryFromReader);
      msg.setAudioPlaybackCancel(value);
      break;
    case 3:
      var value = new proto.audio.AudioTranscript;
      reader.readMessage(value,proto.audio.AudioTranscript.deserializeBinaryFromReader);
      msg.setAudioTranscript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ServerPush.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ServerPush.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ServerPush} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ServerPush.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudioResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackCancel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.Event.serializeBinaryToWriter
    );
  }
  f = message.getAudioTranscript();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.AudioTranscript.serializeBinaryToWriter
    );
  }
};


/**
 * optional AudioFileUpload audio_response = 1;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ServerPush.prototype.getAudioResponse = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 1));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ServerPush} returns this
*/
proto.audio.ServerPush.prototype.setAudioResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ServerPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerPush} returns this
 */
proto.audio.ServerPush.prototype.clearAudioResponse = function() {
  return this.setAudioResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerPush.prototype.hasAudioResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event audio_playback_cancel = 2;
 * @return {?proto.audio.Event}
 */
proto.audio.ServerPush.prototype.getAudioPlaybackCancel = function() {
  return /** @type{?proto.audio.Event} */ (
    jspb.Message.getWrapperField(this, proto.audio.Event, 2));
};


/**
 * @param {?proto.audio.Event|undefined} value
 * @return {!proto.audio.ServerPush} returns this
*/
proto.audio.ServerPush.prototype.setAudioPlaybackCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ServerPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerPush} returns this
 */
proto.audio.ServerPush.prototype.clearAudioPlaybackCancel = function() {
  return this.setAudioPlaybackCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerPush.prototype.hasAudioPlaybackCancel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AudioTranscript audio_transcript = 3;
 * @return {?proto.audio.AudioTranscript}
 */
proto.audio.ServerPush.prototype.getAudioTranscript = function() {
  return /** @type{?proto.audio.AudioTranscript} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioTranscript, 3));
};


/**
 * @param {?proto.audio.AudioTranscript|undefined} value
 * @return {!proto.audio.ServerPush} returns this
*/
proto.audio.ServerPush.prototype.setAudioTranscript = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ServerPush.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ServerPush} returns this
 */
proto.audio.ServerPush.prototype.clearAudioTranscript = function() {
  return this.setAudioTranscript(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ServerPush.prototype.hasAudioTranscript = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.audio);
