<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <h2>BPMN Modeler</h2>
            <BpmnModeler :processorService="processorService" />
            <div class="modal-buttons">
                <button @click="closeModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BpmnModeler from './BpmnModeler.vue';

export default defineComponent({
    name: 'BpmnModelerModal',
    components: {
        BpmnModeler,
    },
    props: {
        processorService: {
            type: Object,
            required: true
        }
    },
    emits: ['close'],
    setup(props, { emit }) {
        const closeModal = () => {
            emit('close');
        };

        return {
            closeModal,
        };
    },
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    width: 2000px;
    height: 1024px;
    overflow-y: auto;
    z-index: 2001;
    display: flex;
    flex-direction: column;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

h2 {
    margin-top: 0;
}
</style>