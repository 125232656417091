<template>
  <div id="dev-chat" :class="{ 'sidebar-open': isMenuOpen }">
    <div v-if="showTokenAlert" class="api-key-alert">
      OpenAI token is not set in settings. Please set it to use the chat functionality.
    </div>
    <!-- Menu toggle button -->
    <div class="menu-toggle" @click.stop="toggleMenu">☰</div>

    <!-- Sidebar menu -->
    <div class="sidebar" :class="{ 'open': isMenuOpen }" @click.stop>
      <button @click="editTags">Edit Tags</button>
      <button @click="editCorporateAI">Edit Corporate AI</button>
      <button @click="openSettings">Settings</button>
      <button @click="openFilesModal">Manage Files</button>
      <button @click="reset">Reset Chat</button>
      <button @click="toggleAudioMuting" class="audio-control">
        <span class="recording-indicator" :class="{ 'active': isRecording, 'muted': isMicMuted }"></span>
        {{ isMicMuted ? 'Unmute mic' : 'Mute mic' }}
      </button>
      <button @click="toggleFilter">{{ showFilter ? 'Hide Filter' : 'Show Filter' }}</button>
      <button @click="openBpmnModeler">Open BPMN Modeler</button>
      <button @click="openConfigEditor">Edit Config</button>
    </div>

    <div class="filter-container" ref="filterContainer">
      <MessageFilter v-if="showFilter" @apply-simple-filter="applySimpleFilter" @apply-mongo-filter="applyMongoFilter"
        @generate-filter="handleGenerateFilter" />
    </div>

    <!-- Workout Plan Display -->
    <div class="workout-plan">
      <div v-if="taskContext" class="task-context">
        {{ taskContext }}
      </div>
      <pre>{{ sessionData }}</pre>
    </div>

    <!-- Chat content stack -->
    <ChatContent v-for="(chatMessages, index) in filteredChatStack" :key="index" :messages="chatMessages"
      :isLoading="isLoading && index === filteredChatStack.length - 1"
      :showTriggerSystem2Button="showTriggerSystem2Button && index === 0" :depth="index"
      @triggerSystem2="triggerSystem2" @openTraceInfo="openTraceInfo" @close="closeTraceInfo(index)"
      @sendMessage="sendMessage" class="chat-content" />

    <!-- Modals -->
    <TagEditorModal v-if="isTagEditorOpen" :processorService='chatService' @close="closeTagEditor" />
    <CorporateAIEditorModal v-if="isCorporateAIEditorOpen" :processorService='chatService'
      @close="closeCorporateAIEditor" />
    <SettingsModal v-if="isSettingsOpen" @close="closeSettings" @save="saveSettings" />
    <FilesModal v-if="isFilesModalOpen" :processorService='chatService' @close="closeFilesModal" />
    <BpmnModelerModal v-if="isBpmnModelerOpen" :processorService='chatService' @close="closeBpmnModeler" />
    <ConfigEditorModal v-if="isConfigEditorOpen" 
      :processorService='chatService' 
      :show="isConfigEditorOpen"
      @close="closeConfigEditor"
      @save="handleConfigSave" />
  </div>

  <!-- Status bar -->
  <div class="dev-chat-status-bar">
    <div class="status-section">
      <div class="status-item">
        <span class="label">User:</span>
        <span class="value">{{ userName }}</span>
      </div>
      <div class="status-item">
        <span class="label">Email:</span>
        <span class="value">{{ userEmail }}</span>
      </div>
      <button class="status-button" @click="handleShare" title="Share">
        <span class="status-icon">⇪</span>
        <span class="status-text">Share</span>
      </button>
      <button class="status-button" @click="handleReset" title="Reset">
        <span class="status-icon">↺</span>
        <span class="status-text">Reset</span>
      </button>
      <button class="status-button" @click="handleLogout" title="Logout">
        <span class="status-icon">⏻</span>
        <span class="status-text">Logout</span>
      </button>
    </div>
    <div class="status-item server-status">
      <span class="label">Server:</span>
      <span class="value">{{ server }}</span>
      <select v-if="isAdmin" class="server-select" :value="server"
        @change="(e: Event) => switchServer((e.target as HTMLSelectElement).value)">
        <option value="" disabled selected>Switch</option>
        <option v-for="s in availableServers" :key="s" :value="s" :selected="s === server">
          {{ s }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { ProcessorService } from './ProcessorService';
import { Message, Settings } from './types';
import TagEditorModal from './TagEditorModal.vue';
import CorporateAIEditorModal from './CorporateAIEditorModal.vue';
import SettingsModal from './SettingsModal.vue';
import FilesModal from './FilesModal.vue';
import BpmnModelerModal from './BpmnModelerModal.vue';
import { useToast } from 'vue-toast-notification';
import ChatContent from './ChatContent.vue';
import MessageFilter from './MessageFilter.vue';
import './DevChat.vue.css';
import { filterMessages } from "./jsonQuery"
import { RealtimeAudioStreamingService } from './RealtimeApiAudioManager';
import { GameStreamingService } from './GameManager';
import { ServerPush } from '../proto-gen-ref/game_pb';
import ConfigEditorModal from './ConfigEditorModal.vue';

export default defineComponent({
  components: {
    TagEditorModal,
    CorporateAIEditorModal,
    SettingsModal,
    FilesModal,
    BpmnModelerModal,
    ChatContent,
    MessageFilter,
    ConfigEditorModal,
  },
  props: {
    userName: { type: String, required: true },
    userEmail: { type: String, required: true },
    server: { type: String, required: true },
    token: { type: String, required: true },
  },
  setup(props) {
    const $toast = useToast();
    const chatStack = ref<Message[][]>([[]]);
    const activeFilter = ref<string>('');
    const activeFilterType = ref<string>('simple');
    const showFilter = ref(false);
    const filterContainer = ref<HTMLElement | null>(null);
    const isMenuOpen = ref(localStorage.getItem('sidebarState') === 'open');
    const isLoading = ref(false);
    const isTagEditorOpen = ref(false);
    const isCorporateAIEditorOpen = ref(false);
    const isBpmnModelerOpen = ref(false);
    const isSettingsOpen = ref(false);
    const isFilesModalOpen = ref(false);
    const settings = ref<Settings>({
      openAiApiKey: '',
      chatModel: 'gpt-4o-mini',
    });
    const showTokenAlert = ref(true);
    const messageDict = ref<{ [key: number]: Message }>({});
    const sessionData = ref('');
    const taskContext = ref('');

    const gameService = GameStreamingService.getInstance();
    let isAutomab = false;
    if (props.server.includes('automab')) {
      isAutomab = true;
    }
    const chatService = new ProcessorService(props.token, gameService, isAutomab);

    const audioService = RealtimeAudioStreamingService.getInstance();

    // switch environment support
    const availableServers = ref<string[]>([]);
    const isAdmin = ref(false);

    const filteredChatStack = computed(() => {
      // Implement the filtering logic here
      return chatStack.value.map(conversation => {
        if (!conversation) return [];
        try {
          return filterMessages(conversation || [], activeFilter.value, activeFilterType.value);
        } catch (error) {
          console.error('Error filtering messages:', error);
          return [];
        }
      }).filter(Boolean); // Remove any undefined or null entries
    });

    const showTriggerSystem2Button = computed(() => {
      return !isLoading.value && chatStack.value[0]?.filter(m => ['ToPlayer', 'AIResponse', 'PlayerInput'].includes(m.msgType)).length > 0;
    });

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
      localStorage.setItem('sidebarState', isMenuOpen.value ? 'open' : 'closed');
    };

    const toggleFilter = (event: Event) => {
      event.stopPropagation();
      showFilter.value = !showFilter.value;
    };

    const applySimpleFilter = async (filter: string) => {
      activeFilterType.value = "simple";
      activeFilter.value = filter;
    };

    const applyMongoFilter = async (filter: string) => {
      activeFilterType.value = "mongo";
      activeFilter.value = filter;
    };

    const handleLogout = async () => {
      window.location.reload();
    };

    const handleShare = async () => {
      // Construct the base URL without any query parameters
      const baseUrl = window.location.href.split('?')[0];
      // Create the share URL with just the token
      const shareUrl = `${baseUrl}?token=${props.token}`;

      navigator.clipboard.writeText(shareUrl).then(() => {
        $toast.success('Share link copied to clipboard', {
          position: 'top-right',
          duration: 3000,
        });
      }).catch(err => {
        console.error('Failed to copy URL:', err);
        $toast.error('Failed to copy share link', {
          position: 'top-right',
          duration: 3000
        });
      });
    };

    const handleGenerateFilter = (prompt: string, callback: (filter: string, filterType: string) => void) => {
      console.log('Generating filter for prompt:', prompt);

      chatService.handleNaturalLangQuery(prompt).then((response) => {
        if (response.problems) {
          console.error('Error generating filter:', response.problems);
          $toast.error(`Problems generating filter: ${response.problems}`, {
            position: 'top-right',
            duration: 3000
          });
          return;
        }

        if (response.query && response.query.length > 0) {
          activeFilterType.value = "mongo";
          activeFilter.value = response.query;

          console.log('Generated filter:', activeFilter.value);

          callback(activeFilter.value, "mongo");

          $toast.success('Filter generated successfully', {
            position: 'top-right',
            duration: 3000
          });
        }
      }).catch((error) => {
        console.error('Error generating filter:', error);
        $toast.error('Failed to generate filter', {
          position: 'top-right',
          duration: 3000
        });
      });
    };

    const fetchAllMessages = async () => {
      if (!chatStack.value[0]) {
        chatStack.value[0] = []; // Ensure chatStack.value[0] is always initialized
      }
      if (chatService) {
        try {
          const messages = await chatService.getAllMessages();
          if (messages && Array.isArray(messages)) {
            chatStack.value[0] = messages;

            messageDict.value = messages.reduce((dict, message) => {
              if (message && message.id !== undefined) {
                dict[message.id] = message;
              } else {
                console.error('Message or Message ID is missing:', message);
              }
              return dict;
            }, {} as { [key: number]: Message });

            console.log('Fetched all messages:', chatStack.value[0]);
            console.log('Constructed message dictionary:', messageDict.value);
          } else {
            console.error('Invalid messages data received:', messages);
            $toast.error('Invalid data received while fetching messages', {
              position: 'top-right',
              dismissible: true,
              duration: 0
            });
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
          $toast.error((error as Error).message || 'An error occurred while fetching messages', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }
      }
    };

    const sendMessage = async (message: string, depth = 0) => {
      if (message.trim()) {
        const userMessage: Message = {
          id: -1,
          text: message,
          msgType: 'PlayerInput',
          srcContext: 'FE::aitrainer.sendMessage',
          timestamp: new Date()
        };
        chatStack.value[depth].push(userMessage);

        isLoading.value = true;

        try {
          await chatService.chat(message);
          await fetchAllMessages();
        } catch (error) {
          $toast.error((error as Error).message || 'An error occurred while processing your message', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        } finally {
          isLoading.value = false;
        }
      }
    };

    const editTags = () => {
      isMenuOpen.value = false;
      isTagEditorOpen.value = true;
    };

    const closeTagEditor = () => {
      isTagEditorOpen.value = false;
    };

    const editCorporateAI = () => {
      isMenuOpen.value = false;
      isCorporateAIEditorOpen.value = true;
    };

    const closeCorporateAIEditor = () => {
      isCorporateAIEditorOpen.value = false;
    };

    const openBpmnModeler = () => {
      isMenuOpen.value = false;
      isBpmnModelerOpen.value = true;
    };

    const closeBpmnModeler = () => {
      isBpmnModelerOpen.value = false;
    };

    const reset = async () => {
      try {
        await chatService.resetChat();
        await fetchAllMessages();
      } catch (error) {
        console.error('Failed to reset chat:', error);
        $toast.error((error as Error).message || 'An error occurred while resetting the chat', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      }
    };

    const openSettings = () => {
      isMenuOpen.value = false;
      isSettingsOpen.value = true;
    };

    const closeSettings = () => {
      isSettingsOpen.value = false;
    };

    const saveSettings = (newSettings: Settings) => {
      localStorage.setItem('settings', JSON.stringify(newSettings));
      settings.value = newSettings;
      chatService.setSettings(settings.value);
      showTokenAlert.value = settings.value.openAiApiKey.length == 0;
      closeSettings();
    };

    const openFilesModal = () => {
      isMenuOpen.value = false;
      isFilesModalOpen.value = true;
    };

    const closeFilesModal = () => {
      isFilesModalOpen.value = false;
    };

    const openTraceInfo = (message: Message, depth: number) => {
      if (message.traceInfo && message.traceInfo.length) {
        chatStack.value.splice(depth + 1);
        const traceMessages = message.traceInfo.map(id => messageDict.value[id]).filter(msg => msg !== undefined);
        chatStack.value.push(traceMessages);
      }
    };

    const closeTraceInfo = (depth: number) => {
      if (depth > 0) {
        chatStack.value.splice(depth);
      }
    };

    const triggerSystem2 = async () => {
      try {
        isLoading.value = true;
        await chatService.triggerSystem2();
        await fetchAllMessages();
      } catch (error) {
        $toast.error((error as Error).message || 'An error occurred while triggering System 2 thinking', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      } finally {
        isLoading.value = false;
      }
    };

    const fetchServers = async () => {
      try {
        let servers = await chatService.listServers();
        availableServers.value = servers;
        isAdmin.value = servers.length > 0;
      } catch (error) {
        console.error('Failed to list servers:', error);
      }
    };

    const switchServer = async (newServer: string) => {
      try {
        await chatService.switchServer(newServer);

        // Redirect with the current token
        const url = new URL(window.location.href);
        url.searchParams.set('token', props.token);
        window.location.href = url.toString();
      } catch (error) {
        console.error('Error switching server:', error);
        $toast.error('Failed to switch server', {
          position: 'top-right',
          dismissible: true,
          duration: 3000,
        });
      }
    };

    const handleSpeechMessage = (source: string, text: string) => {
      const msgType = source === 'client' ? "PlayerInput" : "Prompt";
      const speechMessage: Message = {
        id: -1,
        text: text,
        msgType,
        srcContext: `FE::audioService.speechMessage::${msgType}`,
        timestamp: new Date()
      };

      // Add the message to the first conversation in the stack
      if (!chatStack.value[0]) {
        chatStack.value[0] = [];
      }
      chatStack.value[0].push(speechMessage);
    };

    const handleReset = () => {
      const baseUrl = window.location.href.split('?')[0];
      const resetUrl = `${baseUrl}?token=${props.token}`;
      window.location.href = resetUrl;
    };

    onMounted(async () => {
      await chatService.init();
      const savedSettings = localStorage.getItem('settings');
      if (savedSettings) {
        settings.value = JSON.parse(savedSettings);
      }
      chatService.setSettings(settings.value);
      chatService.resetChat();
      showTokenAlert.value = settings.value.openAiApiKey.length == 0;

      await fetchAllMessages();
      await fetchServers();

      // Add speech message handler to audio service
      audioService.onSpeechMessage((source: string, text: string) => {
        handleSpeechMessage(source, text);
      });

      // Register for push notifications
      gameService.onPushNotification((pushCase: ServerPush.PushCase, message: string) => {
        switch (pushCase) {
          case ServerPush.PushCase.TASK_START:
            taskContext.value = `${message}`;
            console.log(`[DevChat] Received push notification: TASK_START, ${message}`);
            break;
          case ServerPush.PushCase.TASK_COMPLETE:
            fetchAllMessages();
            console.log(`[DevChat] Received push notification: TASK_COMPLETE, ${message}`);
            break;
          default:
            console.error(`[DevChat] Received unhandled push notification: ${pushCase}, ${message}`);
            break;
        }
      });

      // Subscribe to session data updates
      gameService.onSessionDataUpdate((data: string) => {
        sessionData.value = data;
      });
    });

    const isRecording = computed(() => audioService.isRecording());
    const isMicMuted = computed(() => audioService.isMicMuted());
    const isPlayingBack = computed(() => audioService.isPlayingBack());

    const isConfigEditorOpen = ref(false);

    const openConfigEditor = () => {
      isMenuOpen.value = false;
      isConfigEditorOpen.value = true;
    };

    const closeConfigEditor = () => {
      isConfigEditorOpen.value = false;
    };

    const handleConfigSave = (config: any) => {
      console.log('Saving config:', config);
      // Handle the config save here
    };

    return {
      chatStack,
      isRecording,
      isMicMuted,
      isPlayingBack,
      toggleAudioMuting: () => audioService.toggleAudioMuting(),
      activeFilter,
      activeFilterType,
      showFilter,
      filteredChatStack,
      filterContainer,
      isMenuOpen,
      isLoading,
      isTagEditorOpen,
      isCorporateAIEditorOpen,
      isBpmnModelerOpen,
      isSettingsOpen,
      isFilesModalOpen,
      settings,
      showTokenAlert,
      messageDict,
      chatService,
      showTriggerSystem2Button,
      toggleMenu,
      toggleFilter,
      applySimpleFilter,
      applyMongoFilter,
      handleGenerateFilter,
      sendMessage,
      editTags,
      closeTagEditor,
      editCorporateAI,
      closeCorporateAIEditor,
      openBpmnModeler,
      closeBpmnModeler, reset,
      openSettings,
      closeSettings,
      saveSettings,
      openFilesModal,
      closeFilesModal,
      openTraceInfo,
      closeTraceInfo,
      triggerSystem2,
      handleLogout,
      handleShare,
      availableServers,
      isAdmin,
      switchServer,
      handleSpeechMessage,
      handleReset,
      sessionData,
      taskContext,
      isConfigEditorOpen,
      openConfigEditor,
      closeConfigEditor,
      handleConfigSave,
    };
  },
});
</script>

<style scoped>
#dev-chat {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  transition: padding-left 0.3s ease;
  padding-bottom: 32px;
  height: 100vh;
  box-sizing: border-box;
}

.workout-plan {
  flex: 0 0 30vh;
  /* Don't grow, don't shrink, stay at 30vh */
  overflow-y: auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  border: 1px solid #dee2e6;
  min-height: 30vh;
  /* Ensure minimum height */
}

.workout-plan pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
}

/* Make ChatContent take remaining space */
.chat-content {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;
  /* Allow shrinking below content size */
}

.filter-container {
  position: relative;
}

.audio-control {
  position: relative;
  padding-left: 30px;
}

.recording-indicator {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff0000;
  opacity: 1;
}

.recording-indicator.muted {
  background-color: #888;
}

.recording-indicator.active {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.workout-plan {
  height: 30vh;
  overflow-y: auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  border: 1px solid #dee2e6;
}

.workout-plan pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
}
</style>
