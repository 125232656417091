<template>
    <div class="form-field" v-if="!shouldHideField">
        <label>{{ fieldDef.name }} <span v-if="fieldDef.required">*</span></label>

        <!-- Dictionary Handling -->
        <div v-if="isDictionary">
            <div v-for="(val, key) in dictionaryValue" :key="key" class="dictionary-item">
                <div class="dictionary-item-key">
                    <input type="text" v-model="currentKeyEdits[key]" />
                    <button type="button" @click="updateDictionaryKey(key)">Update Key</button>
                    <button type="button" @click="removeDictionaryKey(key)">Remove</button>
                </div>

                <div class="dictionary-item-value">
                    <!-- Render the dictionary value using DynamicFormField again -->
                    <DynamicFormField :fieldDef="dictionaryFieldDef" v-model:value="dictionaryValue[key]" />
                </div>
            </div>

            <div class="dictionary-add">
                <input type="text" v-model="newKey" placeholder="New key" />
                <button type="button" @click="addDictionaryKey">Add Key</button>
            </div>
        </div>

        <!-- If the field is an array -->
        <div v-else-if="fieldDef.isArray">
            <div v-for="(item, index) in arrayValue" :key="index" class="array-item">
                <button type="button" class="remove-button" @click="removeArrayItem(index)">✕</button>
                <!-- If this is a complex array item, recursively handle it -->
                <RecursiveFormFields v-if="isComplexType" v-model:value="arrayValue[index]"
                    :fieldsMetadata="childFieldsArr" />
                <!-- Otherwise, primitive items -->
                <template v-else>
                    <select v-if="fieldDef.isEnum" v-model="arrayValue[index]">
                        <option v-for="value in fieldDef.enumValues" :key="value" :value="value">
                            {{ value }}
                        </option>
                    </select>
                    <textarea v-else-if="fieldDef.type === 'string' && fieldDef.uiControl !== 'input'"
                        v-model="arrayValue[index]" class="field-textarea" rows="3"></textarea>
                    <input v-else-if="fieldDef.type === 'string' && fieldDef.uiControl === 'input'" type="text"
                        v-model="arrayValue[index]" />
                    <input v-else-if="fieldDef.type === 'number'" type="number" v-model.number="arrayValue[index]" />
                    <input v-else-if="fieldDef.type === 'boolean'" type="checkbox" v-model="arrayValue[index]" />
                    <input v-else type="text" v-model="arrayValue[index]" />
                </template>
            </div>
            <button type="button" @click="addArrayItem">Add Item</button>
        </div>

        <!-- If single complex object -->
        <div v-else-if="isComplexType">
            <RecursiveFormFields v-model:value="internalValue" :fieldsMetadata="childFieldsArr" />
        </div>

        <!-- Otherwise, a single primitive type -->
        <div v-else>
            <select v-if="fieldDef.isEnum" v-model="internalValue">
                <option v-for="value in fieldDef.enumValues" :key="value" :value="value">
                    {{ value }}
                </option>
            </select>
            <textarea v-else-if="fieldDef.type === 'string' && fieldDef.uiControl !== 'input'" v-model="internalValue"
                class="field-textarea" rows="3"></textarea>
            <input v-else-if="fieldDef.type === 'string' && fieldDef.uiControl === 'input'" type="text"
                v-model="internalValue" />
            <input v-else-if="fieldDef.type === 'number'" type="number" v-model.number="internalValue" />
            <input v-else-if="fieldDef.type === 'boolean'" type="checkbox" v-model="internalValue" />
            <input v-else type="text" v-model="internalValue" />
        </div>
    </div>
</template>

<script setup>
import { computed, ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import RecursiveFormFields from './RecursiveFormFields.vue';
import DynamicFormField from './DynamicFormField.vue';

const props = defineProps({
    fieldDef: {
        type: Object,
        required: true
    },
    value: {
        type: [String, Number, Boolean, Object, Array],
        default: undefined
    }
});

const emit = defineEmits(['update:value']);

const primitiveTypes = ['string', 'number', 'boolean'];

const shouldHideField = computed(() => {
    return props.fieldDef.hidden || props.fieldDef.name === 'callback';
});

const isComplexType = computed(() => {
    // It's complex if it has childProps, or if there's a dictionary, or if it's otherwise not a primitive/enum
    if (props.fieldDef.isEnum) return false;
    if (primitiveTypes.includes(props.fieldDef.type)) return false;
    const hasChildProps = props.fieldDef.childProps && Object.keys(props.fieldDef.childProps).length > 0;
    const hasDictionary = !!props.fieldDef.dictionary;
    return hasChildProps || hasDictionary;
});

const isDictionary = computed(() => {
    // Only treat as dictionary if no childProps are present
    if (props.fieldDef.childProps && Object.keys(props.fieldDef.childProps).length > 0) return false;
    return !!props.fieldDef.dictionary;
});

const childFieldsArr = computed(() => {
    if (isComplexType.value && props.fieldDef.childProps) {
        return Object.values(props.fieldDef.childProps);
    }
    return [];
});

const internalValue = computed({
    get() {
        return props.value;
    },
    set(val) {
        emit('update:value', val);
    }
});

// Arrays
const arrayValue = computed({
    get() {
        return Array.isArray(internalValue.value) ? internalValue.value : [];
    },
    set(val) {
        emit('update:value', val);
    }
});

// Dictionaries
const dictionaryValue = computed({
    get() {
        const val = internalValue.value;
        return (val && typeof val === 'object' && !Array.isArray(val)) ? val : {};
    },
    set(val) {
        emit('update:value', val);
    }
});

const dictionaryFieldDef = computed(() => {
    if (!isDictionary.value) return null;
    return props.fieldDef.dictionary.value;
});

const newKey = ref('');
const currentKeyEdits = ref({});

onMounted(() => {
    syncCurrentKeyEdits();
});

watch(dictionaryValue, () => {
    syncCurrentKeyEdits();
});

function syncCurrentKeyEdits() {
    const val = dictionaryValue.value;
    for (const k of Object.keys(val)) {
        if (currentKeyEdits.value[k] === undefined) {
            currentKeyEdits.value[k] = k;
        }
    }
}

function addDictionaryKey() {
    const key = newKey.value.trim();
    if (!key) return;
    if (Object.prototype.hasOwnProperty.call(dictionaryValue.value, key)) return; // already exists

    dictionaryValue.value = {
        ...dictionaryValue.value,
        [key]: createInitialValue(dictionaryFieldDef.value)
    };
    currentKeyEdits.value[key] = key; // track edits
    newKey.value = '';
}

function removeDictionaryKey(key) {
    const newDict = { ...dictionaryValue.value };
    delete newDict[key];
    dictionaryValue.value = newDict;
    delete currentKeyEdits.value[key];
}

function updateDictionaryKey(oldKey) {
    const newDict = { ...dictionaryValue.value };
    const newK = currentKeyEdits.value[oldKey].trim();
    if (!newK || newK === oldKey || Object.prototype.hasOwnProperty.call(newDict, newK)) return;

    newDict[newK] = newDict[oldKey];
    delete newDict[oldKey];
    dictionaryValue.value = newDict;
    currentKeyEdits.value[newK] = newK;
    delete currentKeyEdits.value[oldKey];
}

// Helper to create initial values for new dictionary entries or complex objects
function createInitialValue(def) {
    // If there are childProps, this is a complex object
    if (def.childProps && Object.keys(def.childProps).length > 0) {
        const obj = {};
        for (const [name, cDef] of Object.entries(def.childProps)) {
            obj[name] = createInitialValue(cDef);
        }
        return obj;
    }

    // If there's a dictionary, initialize it as an empty object
    if (def.dictionary) {
        return {};
    }

    // If it's an array type, initialize as empty array
    if (def.isArray) {
        return [];
    }

    // For primitive types
    if (def.type === 'string') return '';
    if (def.type === 'number') return 0;
    if (def.type === 'boolean') return false;
    // If we have an enum or something else, just default to empty string or a suitable value
    return '';
}

// Arrays
function createNewItemFromChildProps(childProps) {
    const newItem = {};
    for (const [name, def] of Object.entries(childProps)) {
        if (def.isArray) {
            newItem[name] = [];
        } else if (def.dictionary) {
            newItem[name] = {};
        } else if (def.childProps && Object.keys(def.childProps).length > 0) {
            newItem[name] = createNewItemFromChildProps(def.childProps);
        } else {
            if (def.type === 'string') {
                newItem[name] = '';
            } else if (def.type === 'number') {
                newItem[name] = 0;
            } else if (def.type === 'boolean') {
                newItem[name] = false;
            } else {
                newItem[name] = '';
            }
        }
    }
    return newItem;
}

function addArrayItem() {
    const newItem = isComplexType.value
        ? createNewItemFromChildProps(props.fieldDef.childProps || {})
        : (props.fieldDef.isEnum && props.fieldDef.enumValues?.length > 0)
            ? props.fieldDef.enumValues[0]
            : props.fieldDef.type === 'string'
                ? ''
                : null;

    arrayValue.value = [...arrayValue.value, newItem];
}

function removeArrayItem(index) {
    const newArray = [...arrayValue.value];
    newArray.splice(index, 1);
    arrayValue.value = newArray;
}
</script>

<style scoped>
.form-field {
    margin-bottom: 1.5rem;
}

.form-field label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.field-textarea {
    width: 100%;
    resize: vertical;
    box-sizing: border-box;
    min-height: 60px;
    padding: 0.5rem;
    font-family: inherit;
    font-size: 1rem;
}

input {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    font-family: inherit;
    font-size: 1rem;
}

.dictionary-item {
    border: 1px solid #ddd;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.dictionary-item-key {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.dictionary-add {
    margin-top: 1rem;
}

.array-item {
    position: relative;
    padding: 0.5rem;
    border: 1px solid #ddd;
    margin-bottom: 0.5rem;
}

.remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

/* Ensure the button only appears when hovering over its specific array item */
.array-item:hover>.remove-button {
    opacity: 1;
}

select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
}

select:focus {
    outline: none;
    border-color: #666;
}
</style>
